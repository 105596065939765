import React from 'react'
import Main from '../template/Main'

export default props => 
   <Main>
      <h1> Welcome!</h1>
      <hr />
      <p>
         System to exemplify the construction of a register of users.
      </p>
   </Main>